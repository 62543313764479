import React from "react"
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import Section from "../components/section"
import { Link } from 'gatsby'
import CloseSvg from "../components/close"
import redirect from "../utils/redirect"
import { graphql } from 'gatsby'
import Arrow from "../components/arrow"
import Img from "gatsby-image"

class Gallery extends React.Component {

  state = {
    scrollIndex: 1,
    selectedIndex: 0,
    itemsOutOfScreenLeft: 0,
    
    //m
    minScrollIndex: 1,
    maxScrollIndex: 0,
    // scrollIndex: 1
  }

  componentDidMount() {
    this.scrollArea = document.querySelector(".gallery__scrollarea");

    const firstPhotoNode = document.querySelector(".gallery__scrollarea > .gallery__photos > .photo");
    const galleryWrapNode = document.querySelector( '.gallery__wrap' );
    const scrollAreaNode = document.querySelector( '.gallery__scrollarea' );

    if ( !(firstPhotoNode && galleryWrapNode && scrollAreaNode ) ) {
      return false;
    }

    const {
      data: { allFile: { edges: photos } }
    } = this.props

    const itemBorder = 4;
    let itemWidth = firstPhotoNode.offsetWidth + itemBorder*2;
    const galleryWidth = galleryWrapNode.offsetWidth;
    const isMobileGallery = document.getElementById( 'gallery-section' ).offsetWidth <= 414;
    const itemsOnScreen = galleryWidth / itemWidth;
    const itemCount = photos.length;
    let maxScrollIndex = 0;

    // m
    if ( isMobileGallery ) {
      itemWidth = firstPhotoNode.offsetWidth;
      // const galleryWidth = galleryWrapNode.offsetWidth;
      // const galleryScrollWidth = scrollAreaNode.scrollWidth;
      // const itemsOnScreen = galleryWidth / itemWidth;
      maxScrollIndex = photos.length;
    }

    this.setState((state) => ({
      itemWidth,
      isMobileGallery,
      maxScrollIndex, // m
      itemCount,
      itemsOnScreen,
      selectedPhoto: photos.length > 0 ? photos[0] : null
    }),
    () => {

      if ( isMobileGallery && this.scrollArea ) {
        this.scrollArea.addEventListener('scroll', (ev) => {
          clearTimeout(this.debounced);
          this.debounced = setTimeout(() => {
          
            const scrollLeft = ev.target.scrollLeft;
            
            let newScrollIndex = Math.floor( scrollLeft / this.state.itemWidth ) + 1;
            let scrollMod = scrollLeft - ( (newScrollIndex-1) * itemWidth );

            if ( scrollMod > itemWidth / 2 ) {
              newScrollIndex += 1;
            }

            this.setState((state)=>({
                scrollIndex: newScrollIndex
              }),
              () => {
                isMobileGallery ? this.scrollMPhotos() : this.scrollPhotos()
              }
            )
          }, 100);
        });
      }
    });

    // window.removeEventListener("orientationchange", this.onOrientationChange);
    window.addEventListener("orientationchange", this.onOrientationChange);

  }

  onOrientationChange = (event) => {
    redirect("/");
  }

  render() {

    const {
      data: {
        site: {
          siteMetadata: {
            texts: { sections }
          },
        },
        allFile: { edges: photos }
      },
    } = this.props

    return (
      <ModalRoutingContext.Consumer>
        {({ modal, closeTo }) => (
          <Section
            name="gallery"
          >
            {modal ? this.renderAsModalContent({closeTo, texts: sections, photos}) : this.renderAsPageContent()}
          </Section>
        )}
      </ModalRoutingContext.Consumer>
    )
  }

  renderAsModalContent(parameters) {
    if (this.state.isMobileGallery) {
      return this.renderMGallery(parameters);
    }
    else{
      return this.renderGallery(parameters);
    }
  }

  renderMGallery(parameters) {
    return (
      <div className="gallery__wrap">
        <Link to={parameters.closeTo} className="close-btn">
          <CloseSvg />
        </Link>
        <h5 className="gallery__header">{parameters.texts.works.galleryHeaderText}</h5>
        <div className="gallery__controls">
          {this.state.scrollIndex>this.state.minScrollIndex&&<div
              className="arrow arrow-left"
              onClick={this.handleMLeftClick}
              onKeyDown={()=>{}}
              role="button"
              aria-label="scroll left"
              tabIndex="0"
              >
              <Arrow extent="h"/>
            </div>}
            {this.state.scrollIndex<=this.state.minScrollIndex&&<div className="arrow arrow-left ghost" />}

            <span className="pagerinfo"><i>{this.state.scrollIndex}/</i>{parameters.photos.length}</span>
            {this.state.scrollIndex<this.state.maxScrollIndex&&<div
              className="arrow arrow-right"
              onClick={this.handleMRightClick}
              onKeyDown={()=>{}}
              role="button"
              aria-label="scroll right"
              tabIndex="0"
            >
              <Arrow extent="h" flipped/>
            </div>}
            {this.state.scrollIndex>=this.state.maxScrollIndex&&<div className="arrow arrow-right ghost" />}
        </div>
        <div className="gallery__scrollarea">
          <div className="gallery__photos">
            {Array.isArray(parameters.photos)&&parameters.photos.map((photo,i)=>(
              <div key={i} className="photo">
                <Img
                  fluid={photo.node.childImageSharp.fluid}
                  alt={photo.node.base.split(".")[0]}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  renderGallery(parameters) {
    const {selectedPhoto,selectedIndex} = this.state;
    return (
      <div className="gallery__wrap">
        <Link to={parameters.closeTo} className="close-btn">
          <CloseSvg />
        </Link>
        <h5 className="gallery__header">{parameters.texts.works.galleryHeaderText}</h5>
        <div className="gallery__main-content">
          <div className="gallery__large-photo">
              {selectedPhoto&&<Img
                fluid={selectedPhoto.node.childImageSharp.fluid}
                alt={selectedPhoto.node.base.split(".")[0]}
              />}
          </div>
          <div className="gallery__controls">
            <div
                className="arrow arrow-left"
                onClick={this.handleLeftClick}
                onKeyDown={()=>{}}
                role="button"
                aria-label="scroll left"
                tabIndex="0"
                >
                <Arrow extent="h"/>
              </div>
              {/*<div className="arrow arrow-left ghost" />*/}

              {/*<span className="pagerinfo"><i>{this.state.scrollIndex}/</i>{parameters.photos.length}</span>*/}
              <div
                className="arrow arrow-right"
                onClick={this.handleRightClick}
                onKeyDown={()=>{}}
                role="button"
                aria-label="scroll right"
                tabIndex="0"
              >
                <Arrow extent="h" flipped/>
              </div>
              {/*<div className="arrow arrow-right ghost" />*/}
          </div>
        </div>
        <div className="gallery__scrollarea">
          <div className="gallery__photos">
            {Array.isArray(parameters.photos)&&parameters.photos.map((photo,i)=>(
              <div
                role="button"
                key={i}
                className={["photo",(selectedIndex===i?"selected":"")].filter(s=>s.length>0).join(' ')}
                onClick={()=>this.handleTnClick(photo,i)}
                tabIndex="0"
                onKeyDown={()=>{}}
              >
                {/*<span style={{position:"absolute",color:"white",fontSize:"28px",zIndex:2}}>{i}</span>*/}
                <Img
                  fluid={photo.node.childImageSharp.fluid}
                  alt={photo.node.base.split(".")[0]}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
  
  renderAsPageContent() {
    return redirect("/");
  }

  handleTnClick = (photo,index) => {
    let { itemsOnScreen, itemsOutOfScreenLeft, itemCount } = this.state;

    const onScreenIndex = index - itemsOutOfScreenLeft;
    const wholeItemsOnScreen = Math.floor(itemsOnScreen);
    const itemsCountHalf = Math.floor(wholeItemsOnScreen / 2);

    if ( onScreenIndex < itemsCountHalf ) {
      const scrollBy = Math.abs(onScreenIndex - itemsCountHalf);
      itemsOutOfScreenLeft = Math.max( 0, itemsOutOfScreenLeft - scrollBy );
    }
    else if ( onScreenIndex >= wholeItemsOnScreen - itemsCountHalf ) {
        const scrollBy = onScreenIndex - itemsCountHalf;
        itemsOutOfScreenLeft = Math.min( itemsOutOfScreenLeft + scrollBy, itemCount - wholeItemsOnScreen );
    }

    this.setState((state,props)=>({
      selectedPhoto: photo,
      selectedIndex: index,
      itemsOutOfScreenLeft
    }),
    this.scrollPhotos
    );
  }

  scrollPhotos = (opts) => {
    clearTimeout(this.debounced);
    this.debounced = setTimeout(() => {

      let { itemWidth, itemsOutOfScreenLeft } = this.state;

      this.scrollArea.scroll({
        top: 0,
        left: itemWidth*itemsOutOfScreenLeft,
        behavior: 'smooth'
      });
    }, 100);
  }

  handleLeftClick = (ev) => {
    // if ( ev.keyCode && ev.keyCode !== 37 ) {
    //   return false;
    // }

    const {
      data: { allFile: { edges: photos } }
    } = this.props

    let { selectedIndex, itemsOutOfScreenLeft } = this.state;
    selectedIndex = Math.max(0,selectedIndex-1);
    let selectedPhoto = photos[selectedIndex];

    itemsOutOfScreenLeft = Math.max( 0, itemsOutOfScreenLeft - 1 );

    this.setState((state,props)=>({
      selectedIndex,
      selectedPhoto,
      itemsOutOfScreenLeft
    }),
      this.scrollPhotos
    );
  }
  
  handleRightClick = (ev) => {
    // if ( ev.keyCode && ev.keyCode !== 39 ) {
    //   return false;
    // }
    
    const {
      data: { allFile: { edges: photos } }
    } = this.props

    let { selectedIndex, itemsOutOfScreenLeft, itemsOnScreen, itemCount } = this.state;
    selectedIndex = Math.min(selectedIndex+1, photos.length-1);
    let selectedPhoto = photos[selectedIndex];

    const wholeItemsOnScreen = Math.floor(itemsOnScreen);
    itemsOutOfScreenLeft = Math.min( itemsOutOfScreenLeft + 1, itemCount - wholeItemsOnScreen );

    this.setState((state,props)=>({
      selectedIndex,
      selectedPhoto,
      itemsOutOfScreenLeft
    }),
      this.scrollPhotos
    );
  }

  //m
  handleMLeftClick = (target) => {
    this.setState((state,props)=>({
      scrollIndex: Math.max(state.scrollIndex-1,state.minScrollIndex)
    }),
    this.scrollMPhotos
    );
  }

  handleMRightClick = (target) => {
    this.setState((state,props)=>({
        scrollIndex: Math.min(state.scrollIndex+1,state.maxScrollIndex)
      }),
      this.scrollMPhotos
    );
  }

  scrollMPhotos = (opts) => {
    clearTimeout(this.debounced);
    this.debounced = setTimeout(() => {

      let { itemWidth, scrollIndex } = this.state;

      this.scrollArea.scroll({
        top: 0,
        left: itemWidth*(scrollIndex-1),
        behavior: 'smooth'
      });
    }, 100);
  }
}

export const menuQuery = graphql`
  query {
        site {
            siteMetadata {
                menu {
                    elements {
                        name, target, w
                    }
                }
                texts {sections{works{galleryHeaderText,fbpageButtonSup,fbpageButtonSub}}}
                content { works { fbAlbumLink } }
            }
        }
        allFile(
          filter: {
            extension: { regex: "/(jpg)|(jpeg)/" }
            relativeDirectory: { eq: "gallery" }
          }
        ) {
          edges {
            node {
              base
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
    }
`

export default Gallery