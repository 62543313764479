import React from "react"
import CloseSvg from "../images/svg/close.svg"

const Close = () => {
    return (
        <span className={[`close-svg`].join(' ').trim()}>
            <CloseSvg />
        </span>
    )
}

export default Close